// let host = process.env.VUE_APP_MYHOST
let authhost = process.env.VUE_APP_AUTHHOST
authhost = 'https://co.online-service.su/'
let host = 'https://co.online-service.su/'
export const config = {
  apiUrl: host + 'api/',
  authUrl: authhost + 'auth/oauth/token',
  client: {
    id: 'fooClientIdPassword',
    secret: 'secret'
  },
  image: (img) => `${host}res/reportphoto_${img.uuid}.${img.extension}`,
  tmpimage: (uuid) => `${config.apiUrl}tempfiles/${uuid}`
}